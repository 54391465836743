import './plugins/prototype';

import Vue from 'vue'
import axios from 'axios'
import router from './router'
import store from './store'
import vuetify, { basicTheme } from './plugins/vuetify'
import { i18n } from './plugins/vue-i18n';
import './plugins/vue-dayjs';
import './plugins/vue-cookies';
import './plugins/vue-youtube';
import './plugins/vue-meta';
import './plugins/vue-dayjs';

import App from './AppMain.vue'

// VueFullPage
import 'vue-fullpage.js/node_modules/fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);

// SVG ICONS
import iconBase from '@/components/plugins/svg/icon-base.vue';
import binRounded from '@/components/plugins/svg/icons/bin-rounded.vue';
import closeRounded from '@/components/plugins/svg/icons/close-rounded.vue';
Vue.component("icon-base", iconBase);
Vue.component("bin-rounded", binRounded);
Vue.component("close-rounded", closeRounded);

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

if(location.protocol != "https:" && (location.hostname == "minart.com")) location.protocol = "https:";

new Vue({
  router,
  store,
  i18n,
  vuetify: vuetify(basicTheme),
  render: h => h(App)
}).$mount('#app')